import withProps from "recompose/withProps";
import withRouteLink from "./withRouteLink";
import withTaxonomies from "@xem/taxonomy-common/classes/withTaxonomies";
import Taxonomies from "@xem/taxonomy-common/classes/Taxonomies";
import Charts from "@xem/stats-common/classes/Stats/Charts";
import Stats from "@xem/stats-common/classes/Stats/Stats";
import withWebShare from "@xem/entity-client-react/components/withWebShare";
import withStatsLocation from "@xem/stats-common/classes/withStatsLocation";
import withStatsDevice from "@xem/stats-common/classes/withStatsDevice";
import withStatsChannel from "@xem/stats-common/classes/withStatsChannel";
import withStatsChart from "@xem/stats-common/classes/withStatsChart";
import onSave from "@xem/entity/classes/onSave";
import withLinkUpdate from "@xem/link-common/classes/withLinkUpdate";
/**
import Detail from '../components/views/DetailView';
 /*/ import loadable from "next/dynamic";
const Detail = loadable(()=>import("../components/views/DetailView").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("../components/views/DetailView")
            ]
    }
});
/**/ let timeout = null;
const DetailContainer = withRouteLink((param)=>{
    let { query: { id  } = {}  } = param;
    return {
        stats: Stats.instanse(),
        charts: Charts.instanse(),
        taxonomies: Taxonomies.instanse(),
        statsProps: {
            id: parseInt(id),
            skip: !parseInt(id)
        }
    };
}, [
    withTaxonomies({}),
    withWebShare,
    withStatsLocation((param)=>{
        let { statsProps  } = param;
        return {
            ...statsProps,
            skip: !!__SERVER__
        };
    }),
    withStatsDevice((param)=>{
        let { statsProps , DeviceType: type  } = param;
        return {
            ...statsProps,
            type
        };
    }),
    withStatsChannel((param)=>{
        let { statsProps  } = param;
        return {
            ...statsProps
        };
    }),
    withStatsChart((param)=>{
        let { statsProps , charts  } = param;
        return {
            ...charts.variables,
            ...statsProps
        };
    }),
    withLinkUpdate,
    withProps((param)=>{
        let { entity , linkCreate , linkUpdate  } = param;
        return {
            onSave: onSave(linkCreate, linkUpdate, {
                entity
            }),
            refetch: func(entity === null || entity === void 0 ? void 0 : entity.refetch)
        };
    }),
    withProps((param)=>{
        let { actions , refetch: callback , onSave , actionRef , onActions , entity , stats  } = param;
        let { id , access  } = entity || {};
        /**
       * bind props for pop-ups
       * @type {*&{access: *, id: *, refetch: popupProps.refetch, entity: *}}
       */ function popupProps(accessType) {
            let accessContent = true;
            if (accessType && access[accessType]) {
                accessContent = access[accessType];
            }
            return {
                id,
                entity,
                access: accessContent,
                callback,
                entityType: "link"
            };
        }
        let action = null, funcCallback = null;
        /** bind action popup */ Object.keys(actions).map((key)=>{
            if (action = actions[key]) {
                action.set = function() {
                    if (funcCallback = onActions[key]) {
                        funcCallback(popupProps(action === null || action === void 0 ? void 0 : action.access));
                    }
                };
            }
        });
        return {};
    }),
    withProps((param)=>{
        let { actions , refetch , onSave , actionRef , onActions , entity , propsEntity , stats  } = param;
        let { ips  } = stats || {}, { title  } = entity || {};
        /** Open QR */ function toggleQR() {
            var ref;
            onActions["qrcode"] && onActions["qrcode"]({
                isQrTag: true,
                toDefault: !!(entity === null || entity === void 0 ? void 0 : (ref = entity.access) === null || ref === void 0 ? void 0 : ref.accessQrDefault),
                data_text: entity === null || entity === void 0 ? void 0 : entity.full,
                values: entity === null || entity === void 0 ? void 0 : entity.qr,
                onSubmit: function(qr, param) {
                    let { onClose  } = param;
                    onSave({
                        qr
                    }).then(refetch).then(onClose).catch(errorCatch);
                }
            });
        }
        let action = null;
        if (toggleQR && (action = actions["link.qrcode"])) {
            action.set = toggleQR;
        }
        return {
            title,
            ips,
            toggleQR
        };
    })
])(Detail);
export default DetailContainer;
