"use strict";
module.exports = {
    "en": {
        "shortlink": "Shorten Link",
        "experience-text": "If you want to manage and edit shortened links and create your own branded domain?",
        "free-trial": "Free Trial",
        "redirecting": "Redirecting to:",
        "path": "To {{value}}",
        "max_input": "Link must have at most {{value}} characters.",
        "min_input": "Link must have at least {{value}} characters.",
        "optimization": "Optimize links on Facebook",
        "breadcrumb": {
            "detail": "General information",
            "edit": "Edit link",
            "redirect": "Edit navigation",
            "qrcode": "Edit Qr Code",
            "list": "List of links"
        },
        "menu": {
            "home": "Home",
            "menu": "Menu",
            "custom": "Edit Link",
            "preview": "Edit display",
            "advanced": "Remarketing",
            "campaign": "Campaign",
            "bio_page": "BIO page",
            "qrcode": "QR Code",
            "protected": "Security",
            "redirect": "Routing",
            "links": "Links"
        },
        "toolbars": {
            "menu": "Menu",
            "edit": "Edit",
            "redirect": "Navigate",
            "campaign": "Campaign",
            "qrcode": "QR Code",
            "view": "Link information",
            "frame": "QR frame",
            "data": "Data",
            "eye": "Pattern",
            "logo": "Logo",
            "color": "Color",
            "protected": "Security",
            "links": "Links"
        },
        "title": {
            "create": "Create shortened link",
            "qrcode": "QR Code Settings",
            "copy": "Copy Link",
            "edit": "Edit link",
            "view": "List of links",
            "redirect": "Navigate"
        },
        "create": {
            "title": "Create shortened link"
        },
        "qrcode": {
            "title": "QR Code Settings",
            "updated": "QR code design saved"
        },
        "copy": {
            "link": "Copy Link"
        },
        "edit": {
            "title": "Edit link"
        },
        "redirect": {
            "title": "Navigation"
        },
        "view": {
            "title": "List of links"
        },
        "list": {
            "title": "List of links",
            "hidden": {
                "title": "List of hidden links"
            },
            "btn": {
                "unpublished": "Temporarily hide link",
                "published": "Link published"
            }
        },
        "stats": {
            "total": {
                "view": "{{value}} views",
                "views": "{{value}} views"
            },
            "shorted": {
                "link": "{{value}} shortened link",
                "links": "{{value}} shortened link "
            },
            "guest": {
                "link": "{{value}} shortened client link",
                "links": "{{value}} shortened client link"
            }
        },
        "statsTwo": {
            "total": {
                "view": "Views",
                "views": "Views"
            },
            "shorted": {
                "link": "Shortened link",
                "links": "Shortened link"
            },
            "guest": {
                "link": "Shortened client link",
                "links": "Shortened guest link"
            }
        },
        "views": {
            "label": "views",
            "style1": '<span style="color: #212121;" >{{value}}</span> views',
            "style2": '{{value}}<tspan class="highcharts-br" dy="21" x="150">​</tspan> views',
            "total": 'Total: <span style="color:#000000">{{value}}</span> views',
            "latest": "Latest views",
            "most": "Most views",
            "least": "Least views",
            "qrcode": "Qr Code scans",
            "az": "Order from A to Z",
            "za": "Order from Z to A"
        },
        "shorted": {
            "latest": "New shortened link",
            "longest": "Longest shortened link"
        },
        "notify": {
            "exists": "The link already exists.",
            "unknowError": "Error: Unknown error.",
            "domainAccess": {
                "denied": "You do not have the right to select this domain."
            },
            "exceedTheAllowedQuantity": {
                "limit": "You cannot shorten further. Exceeds the allowed number.",
                "shorten": "You cannot shorten it further. Exceeded the allowed number.",
                "alias": "You cannot change the alias. Exceeded number allowed.",
                "link": "You cannot change the original link. Exceeded number allowed."
            },
            "odd": {
                "title": "List of {{value}} links"
            },
            "even": {
                "title": "List of {{value}} links"
            },
            "unpublished": {
                "odd": {
                    "title": "List of {{value}} hidden links"
                },
                "even": {
                    "title": "List of {{value}} hidden links"
                }
            }
        },
        "links": {
            "title": "List of links",
            "btn": {
                "add": "Create shortened link",
                "edit": "Edit link",
                "view": "View link",
                "delete": "Delete link",
                "cancel": "Cancel"
            },
            "column": {
                "name": "Title",
                "info": "Shortened link",
                "view": "Views",
                "created": "Created date",
                "auth": "Creator"
            },
            "actions": {
                "published": "Public",
                "unpublished": "Hide link",
                "move": "Move campaign",
                "delete": "Delete links",
                "cancel": "Cancel"
            }
        },
        "filter": {
            "actions": {
                "unpublished": "Hidden link"
            }
        },
        "popup": {
            "display": "Display customization",
            "SEOSettings": "SEO Settings",
            "cropping": "Display Image Scale",
            "publish": {
                "link": "Are you sure you should publish the {{value}} link?",
                "links": "Are you sure you publish {{value}} links?"
            },
            "delete": {
                "link": "Are you sure you will remove the {{value}} link?",
                "links": "Are you sure you will delete {{value}} links?"
            },
            "hide": {
                "link": "Are you sure you will hide the {{value}} link?",
                "links": "Are you sure you should hide {{value}} links?"
            }
        },
        "link": {
            "required": "You have not entered the link yet"
        },
        "URL": {
            "label": "Shortened link",
            "required": "You have not entered a url",
            "placeholder": "Shortened link",
            "minimum_url_length": "Minimum url is too short.",
            "invalid": "Invalid URL."
        },
        "form": {
            "title": "List of shortened links",
            "delete": {
                "btn": {
                    "link": "Delete {{value}} link",
                    "links": "Delete {{value}} links"
                }
            },
            "publish": {
                "btn": {
                    "link": "Disclose {{value}} link",
                    "links": "Reveal {{value}} links"
                }
            },
            "hide": {
                "btn": {
                    "link": "Hide {{value}} link",
                    "links": "Hide {{value}} links"
                }
            },
            "URL": {
                "title": "Customize shortened links",
                "updated": "The shortened link customization has been saved."
            },
            "edit": {
                "title": "Customize shortened links"
            },
            "protected": {
                "title": "Password generation and expiration date",
                "help": "To access and improve the security of the link you want to share",
                "password": "Password",
                "expiration": "Expiration date",
                "updated": "The password and shortened link expiration date have been updated."
            },
            "display": {
                "label": "Display preview",
                "title": "Custom display",
                "updated": "The shortened link display option has been updated.",
                "description": {
                    "label": "Description",
                    "empty": "You don't have a description yet",
                    "add": "Add description"
                },
                "edit": {
                    "title": "Title",
                    "description": "Description",
                    "note": {
                        "title": "The SEO title of a link is the first thing people see on Google, social media platforms or SMS. Use words to let people know what your linked page is about",
                        "description": "Tell people more about the content of your link in the most summary terms."
                    }
                }
            },
            "custom": {
                "title": "Customize shortened links",
                "updated": "The shortened link has been updated.",
                "utm": {
                    "label": 'Add UTM <span style="font-size: 12px; color: #686868">(optional)</span>'
                },
                "source": {
                    "label": "Source",
                    "placeholder": "e.g., google, see.link"
                },
                "medium": {
                    "label": "Medium",
                    "placeholder": "e.g., cpc, banner, email"
                },
                "campaign": {
                    "label": "Campaign",
                    "placeholder": "e.g., spring_sale"
                },
                "term": {
                    "label": "Term (optional)",
                    "placeholder": "e.g., something"
                },
                "Nội dung": {
                    "label": "Content (optional)",
                    "placeholder": "e.g., something else"
                }
            },
            "domain": {
                "title": "Choose a domain name",
                "label": "Select domain",
                "placeholder": "Enter the domain to search"
            },
            "qrcode": {
                "title": "Qr code",
                "updated": "Updated qrcode."
            },
            "campaign": {
                "title": "Campaign",
                "help": "This helps you or your team know what this link is for",
                "updated": "The shortened link campaign has been updated.",
                "category": {
                    "label": "Campaign",
                    "placeholder": "Campaign name to create"
                },
                "note": {
                    "label": "Note",
                    "placeholder": "Enter note content"
                }
            },
            "advanced": {
                "title": "Advanced data",
                "default": "Default",
                "help": "To access and improve the security of the link you want to share",
                "updated": "Updated shortened link advanced data.",
                "google": {
                    "label": "Google Analytics 4"
                },
                "Facebook": {
                    "label": "Facebook Pixel"
                }
            },
            "redirect": {
                "title": "App installation redirect",
                "label": "Customize shortened links",
                "updated": "The shortened link settings redirect has been updated.",
                "country": "Country",
                "description": "Instead of a random link, you can edit a link to your liking",
                "store": {
                    "default": "Search in app store",
                    "custom": "Enter direct link"
                },
                "gplay": {
                    "default": "Search in Google play",
                    "custom": "Enter direct link"
                }
            },
            "field": {
                "info": "Display customization",
                "custom": "Custom display",
                "origin": "Show origin",
                "keyword": "keyword",
                "password": "Password",
                "title": "Title",
                "description": "Description",
                "ios": "IOS",
                "android": "Android",
                "appKeyword": "Enter the app to search for"
            },
            "btn": {
                "save": "Save changes"
            }
        }
    },
    "global": {
        "password": "Mật khẩu"
    },
    "vi": {
        "shortlink": "R\xfat Gọn Link",
        "experience-text": "N\xeáu bạn muốn quản lý, chỉnh sửa các li\xean k\xeát rút gọn v\xe0 tạo t\xean mi\xeàn thương hi\xeạu ri\xeang?",
        "free-trial": "D\xf9ng Thử Miễn Ph\xed",
        "redirecting": "Đang chuyển hướng tới:",
        "path": "Tới {{value}}",
        "max_input": "Link phải c\xf3 nhiều nhất {{value}} k\xfd tự.",
        "min_input": "Link phải c\xf3 \xedt nhất {{value}} k\xfd tự.",
        "optimization": "Tối ưu h\xf3a đường link tr\xean Facebook",
        "breadcrumb": {
            "detail": "Th\xf4ng tin tổng hợp",
            "edit": "Chỉnh sửa link",
            "redirect": "Chỉnh sửa điều hướng",
            "qrcode": "Chỉnh sửa Qr Code",
            "list": "Danh s\xe1ch link"
        },
        "menu": {
            "home": "Trang chủ",
            "menu": "Menu",
            "custom": "Sửa Link",
            "preview": "Sửa hiển thị",
            "advanced": "Remarketing",
            "campaign": "Chiến dịch",
            "bio_page": "BIO page",
            "qrcode": "QR Code",
            "protected": "Bảo mật",
            "redirect": "Routing",
            "links": "Links"
        },
        "toolbars": {
            "menu": "Menu",
            "edit": "Chỉnh sửa",
            "redirect": "Điều hướng",
            "campaign": "Chiến dịch",
            "qrcode": "QR Code",
            "view": "Th\xf4ng tin link",
            "frame": "Khung QR",
            "data": "Dữ liệu",
            "eye": "Hoa văn",
            "logo": "Logo",
            "color": "M\xe0u sắc",
            "protected": "Bảo mật",
            "links": "Links"
        },
        "title": {
            "create": "Tạo link r\xfat gọn",
            "qrcode": "C\xe0i đặt QR Code",
            "copy": "Copy Link",
            "edit": "Chỉnh sửa link",
            "view": "Danh s\xe1ch link",
            "redirect": "Điều hướng"
        },
        "create": {
            "title": "Tạo link r\xfat gọn"
        },
        "qrcode": {
            "title": "C\xe0i đặt QR Code",
            "updated": "Đ\xe3 lưu thiết kế QR code"
        },
        "copy.link": "Copy Link",
        "edit.title": "Chỉnh sửa link",
        "redirect.title": "Điều hướng",
        "view.title": "Danh s\xe1ch link",
        "list": {
            "title": "Danh s\xe1ch link",
            "hidden": {
                "title": "Danh s\xe1ch link ẩn"
            },
            "btn": {
                "unpublished": "Tạm ẩn link",
                "published": "C\xf4ng bố link"
            }
        },
        "stats": {
            "total": {
                "view": "{{value}} lượt xem",
                "views": "{{value}} lượt xem"
            },
            "shorted": {
                "link": "{{value}} link r\xfat gọn",
                "links": "{{value}} link r\xfat gọn "
            },
            "guest": {
                "link": "{{value}} link kh\xe1ch r\xfat gọn",
                "links": "{{value}} link kh\xe1ch r\xfat gọn"
            }
        },
        "statsTwo": {
            "total": {
                "view": "Lượt xem",
                "views": "Lượt xem"
            },
            "shorted": {
                "link": "Link r\xfat gọn",
                "links": "Link r\xfat gọn "
            },
            "guest": {
                "link": "Link kh\xe1ch r\xfat gọn",
                "links": "Link kh\xe1ch r\xfat gọn"
            }
        },
        "views": {
            "label": "lượt xem",
            "style1": '<span style="color: #212121;" >{{value}}</span> lượt xem',
            "style2": '{{value}}<tspan class="highcharts-br" dy="21" x="150">​</tspan> lượt xem',
            "total": 'Tổng: <span style="color:#000000">{{value}}</span> lượt xem',
            "latest": "Lượt xem mới nhất",
            "most": "Lượt xem nhiều nhất",
            "least": "Lượt xem \xedt nhất",
            "qrcode": "Lượt qu\xe9t m\xe3 Qr Code",
            "az": "Thứ tự từ A tới Z",
            "za": "Thứ tự từ Z tới A"
        },
        "shorted": {
            "latest": "Link mới r\xfat gọn",
            "longest": "Link r\xfat gọn l\xe2u nhất"
        },
        "notify": {
            "exists": "Li\xean kết đ\xe3 tồn tại.",
            "unknowError": "Lỗi: Lỗi kh\xf4ng x\xe1c định.",
            "domainAccess.denied": "Bạn kh\xf4ng c\xf3 quyền chọn domain n\xe0y.",
            "exceedTheAllowedQuantity": {
                "limit": "Bạn kh\xf4ng thể r\xfat gọn th\xeam được. Vượt qu\xe1 số lượng cho ph\xe9p.",
                "shorten": "Bạn kh\xf4ng thể r\xfat gọn th\xeam được. Vượt qu\xe1 số lượng cho ph\xe9p.",
                "alias": "Bạn kh\xf4ng thể thay đổi alias. Vượt qu\xe1 số lượng cho ph\xe9p.",
                "link": "Bạn kh\xf4ng thể thay đổi link gốc. Vượt qu\xe1 số lượng cho ph\xe9p."
            },
            "odd.title": "Danh s\xe1ch {{value}} link",
            "even.title": "Danh s\xe1ch {{value}} links",
            "unpublished": {
                "odd": {
                    "title": "Danh s\xe1ch {{value}} link ẩn"
                },
                "even": {
                    "title": "Danh s\xe1ch {{value}} links ẩn"
                }
            }
        },
        "links": {
            "title": "Danh s\xe1ch link",
            "btn": {
                "add": "Tạo link r\xfat gọn",
                "edit": "Sửa link",
                "view": "Xem link",
                "delete": "Xo\xe1 link",
                "cancel": "Huỷ bỏ"
            },
            "column": {
                "name": "Ti\xeau đề",
                "info": "Link r\xfat gọn",
                "view": "Lượt xem",
                "created": "Ng\xe0y tạo",
                "auth": "Người tạo"
            },
            "actions": {
                "published": "C\xf4ng khai",
                "unpublished": "Ẩn link",
                "move": "Di chuyển chiến dịch",
                "delete": "Xo\xe1 links",
                "cancel": "Huỷ bỏ"
            }
        },
        "filter.actions.unpublished": "Link ẩn",
        "popup": {
            "display": "Tuỳ chỉnh hiển thị",
            "SEOSettings": "C\xe0i đặt SEO",
            "cropping": "Tỷ Lệ Ảnh Hiển Thị",
            "filter.placeholder": "Links filter",
            "publish": {
                "link": "Bạn c\xf3 chắc m\xecnh c\xf4ng bố {{value}} link chứ ?",
                "links": "Bạn c\xf3 chắc m\xecnh c\xf4ng bố {{value}} links chứ ?"
            },
            "delete": {
                "link": "Bạn c\xf3 chắc m\xecnh sẽ xo\xe1 {{value}} link chứ ?",
                "links": "Bạn c\xf3 chắc m\xecnh sẽ xo\xe1 {{value}} links chứ ?"
            },
            "hide": {
                "link": "Bạn c\xf3 chắc m\xecnh sẽ ẩn {{value}} link chứ ?",
                "links": "Bạn c\xf3 chắc m\xecnh sẽ ẩn {{value}} links chứ ?"
            }
        },
        "link.required": "Bạn chưa nhập link",
        "url": {
            "label": "Link r\xfat gọn",
            "required": "Bạn chưa nhập url",
            "placeholder": "Link r\xfat gọn",
            "minimum_url_length": "Url r\xfat gọn qu\xe1 ngắn.",
            "invalid": "Url kh\xf4ng hợp lệ."
        },
        "form": {
            "title": "Danh s\xe1ch link r\xfat gọn",
            "delete": {
                "btn": {
                    "link": "Xo\xe1 {{value}} link",
                    "links": "Xo\xe1 {{value}} links"
                }
            },
            "publish": {
                "btn": {
                    "link": "C\xf4ng bố {{value}} link",
                    "links": "C\xf4ng bố {{value}} links"
                }
            },
            "hide": {
                "btn": {
                    "link": "Ẩn {{value}} link",
                    "links": "Ẩn {{value}} links"
                }
            },
            "seo.updated": "Đ\xe3 lưu th\xf4ng tin.",
            "url": {
                "title": "Tuỳ chỉnh link r\xfat gọn",
                "updated": "Đ\xe3 lưu tuỳ chỉnh link r\xfat gọn."
            },
            "edit.title": "Tuỳ chỉnh link r\xfat gọn",
            "protected": {
                "title": "Tạo mật khẩu v\xe0 ng\xe0y hết hạn",
                "help": "Để truy cập n\xe2ng cao t\xednh bảo mật của đường link bạn muốn chia sẻ",
                "password": "Mật khẩu",
                "expiration": "Ng\xe0y hết hạn",
                "updated": "Đ\xe3 cập nhật mật khẩu v\xe0 ng\xe0y hết hạn link r\xfat gọn."
            },
            "display": {
                "label": "Xem trước hiển thị",
                "title": "Tuỳ chỉnh hiển thị",
                "updated": "Đ\xe3 cập nhật tuỳ chỉnh hiển thị link r\xfat gọn.",
                "description": {
                    "label": "M\xf4 tả",
                    "empty": "Bạn chưa c\xf3 m\xf4 tả",
                    "add": "Th\xeam m\xf4 tả"
                },
                "edit": {
                    "title": "Ti\xeau đề",
                    "description": "M\xf4 tả",
                    "note": {
                        "title": "Ti\xeau đề SEO của link l\xe0 điều đầu ti\xean mọi người nh\xecn thấy tr\xean Google, c\xe1c nền tảng mạng x\xe3 hội hoặc sms. Sử dụng c\xe1c từ ngữ để cho mọi người biết trang link của bạn n\xf3i về điều g\xec",
                        "description": "Cho mọi người biết th\xeam về nội dung link của bạn bằng những ng\xf4n từ t\xf3m tắt nhất."
                    }
                }
            },
            "custom": {
                "title": "Tuỳ chỉnh link r\xfat gọn",
                "updated": "Đ\xe3 cập nhật link r\xfat gọn.",
                "utm.label": 'Th\xeam UTM <span style="font-size: 12px; color: #686868">(optional)</span>',
                "source": {
                    "label": "Source",
                    "placeholder": "e.g., google, xem.link"
                },
                "medium": {
                    "label": "Medium",
                    "placeholder": "e.g., cpc, banner, email"
                },
                "campaign": {
                    "label": "Campaign",
                    "placeholder": "e.g., spring_sale"
                },
                "term": {
                    "label": "Term (optional)",
                    "placeholder": "e.g., something"
                },
                "content": {
                    "label": "Content (optional)",
                    "placeholder": "e.g., something else"
                }
            },
            "domain": {
                "title": "Chọn t\xean miền",
                "label": "Chọn t\xean miền",
                "placeholder": "Nhập domain cần t\xecm"
            },
            "qrcode": {
                "title": "Qr code",
                "updated": "Đ\xe3 cập nhật qrcode."
            },
            "campaign": {
                "title": "Chiến dịch",
                "help": "Điều n\xe0y gi\xfap cho bạn hoặc nh\xf3m l\xe0m việc biết link n\xe0y d\xf9ng để l\xe0m g\xec",
                "updated": "Đ\xe3 cập nhật chiến dịch link r\xfat gọn.",
                "category": {
                    "label": "Chiến dịch",
                    "placeholder": "T\xean chiến dịch cần tạo"
                },
                "note": {
                    "label": "Ghi ch\xfa",
                    "placeholder": "Nhập nội dung ghi ch\xfa"
                }
            },
            "advanced": {
                "title": "Dữ liệu n\xe2ng cao",
                "default": "Mặc định",
                "help": "Để truy cập n\xe2ng cao t\xednh bảo mật của đường link bạn muốn chia sẻ",
                "updated": "Đ\xe3 cập nhật dữ liệu n\xe2ng cao link r\xfat gọn.",
                "google.label": "Google Analytics 4",
                "facebook.label": "Facebook Pixel"
            },
            "redirect": {
                "title": "Chuyển hướng c\xe0i đặt app",
                "label": "T\xf9y chỉnh link r\xfat gọn",
                "updated": "Đ\xe3 cập nhật chuyển hướng c\xe0i đặt link r\xfat gọn.",
                "country": "Quốc gia",
                "description": "Thay v\xec đường link ngẫu nhi\xean, bạn c\xf3 thể sửa lại 1 đường link theo \xfd th\xedch",
                "store": {
                    "default": "T\xecm kiếm trong app store",
                    "custom": "Nhập link trực tiếp"
                },
                "gplay": {
                    "default": "T\xecm kiếm trong Google play",
                    "custom": "Nhập link trực tiếp"
                }
            },
            "field": {
                "info": "Tuỳ chỉnh hiển thị",
                "origin": "Origin",
                "custom": "Custom",
                "qrcode": "qrcode",
                "keyword": "keyword",
                "password": "Mật khẩu",
                "title": "Ti\xeau đề",
                "description": "M\xf4 tả",
                "ios": "IOS",
                "android": "Android",
                "appKeyword": "Nhập v\xe0o app cần t\xecm kiếm"
            },
            "btn.save": "Lưu thay đổi"
        }
    }
};
